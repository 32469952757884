import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';

import Footer from './components/Footer';
import Index from './routes';
import Contact from './routes/Contact';


function App() {
  return (
    <div id="App">
      <div id="content-wrap">
        <Router>
          <Header></Header>
          <Route path="/" exact component={Index} />
          <Route path="/contact/" exact component={Contact} />
        </Router>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
