import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
ReactDOM.render(<App />, document.getElementById('root'));

// import {initBoxScene, destroyBoxScene} from './3dBox';

// document.body.classList.add('unboxed-enter');
// initBoxScene();

// window.addEventListener('unboxed-elements-released', onElementsReleased);
// function onElementsReleased() {
//   window.removeEventListener('unboxed-elements-released', onElementsReleased);

//   setTimeout(() => {
//     document.body.classList.add('unboxed-enter-active');
//   }, 100);

//   Promise.all([
//     import(/* webpackChunkName: 'react' */ 'react'),
//     import(/* webpackChunkName: 'react' */ 'react-dom'),
//     import(/* webpackChunkName: 'app' */ './App')
//   ])
//   .then(([{default: React}, {default: ReactDOM}, {default: App}]) => {
//     ReactDOM.render(<App />, document.getElementById('root'));

//     const header = document.getElementById('header');
//     const footer = document.getElementById('footer');
//     header.style.width = window.innerWidth;
//     footer.style.width = window.innerWidth;
//     setTimeout(() => {
//       document.body.classList.remove('unboxed-enter', 'unboxed-enter-active');
//       header.style.width = undefined;
//       footer.style.width = undefined;
//       window.dispatchEvent(new Event('unboxed-elements-arranged'));
//     }, 1000);
//   });
// }


// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// // serviceWorker.unregister();
