import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div id="contact" className="mdc-card">
      <p>Call Nick on <a href="tel:+61413780480">0413 780 480</a></p>
      <p>email: <a href="mailto:support@unboxed.software">support@unboxed.software</a></p>
    </div>
  )
}

export default Contact;
