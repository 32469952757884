import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <div id="header">
      <h1>
        <Link to="/">
        <div className="heading__UNBOXED">UNBO<span className="unboxedX rotated">X</span>ED</div>
        <div className="heading__SOFTWARE">SOFTWARE</div>
        </Link>
      </h1>
      <div className="nav">
        <ul>
          <li>
            <Link to="/contact/">Contact Us</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Header;
