import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div id="footer">
      <small>Copyright © 2019 Unboxed Software - All Rights Reserved.</small>
    </div>
  )
}

export default Footer;
