import React from 'react';
import './index.css';

function Index() {
  return (
    <div id="main-page">
      <div className="jumbotron">
        <h1>Software Consulting and Development</h1>
        <p>Specialising in next-generation APIs and serverless architecture.</p>
      </div>

      <div id="content">
        <div className="mdc-card">
          <h2>Serverless Architecture</h2>
          <p>Save thousands of dollars and scale from zero to millions on demand</p>
        </div>

        <div className="mdc-card">
          <h2>DevOps</h2>
          <p>Automate the procesesses that cost you time and money</p>
        </div>

        <div className="mdc-card">
          <h2>Continuous Integration &amp; Delivery</h2>
          <p>Automated testing and deployment to production</p>
        </div>

        <div className="mdc-card">
          <h2>Web App Development</h2>
          <p>Angular, Polymer, React, Vue</p>
        </div>
      </div>
    </div>
  )
}

export default Index;
